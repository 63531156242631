import React from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { CommunitySection } from '../../modules/common/components/community-section';
import { Button, Row } from 'react-bootstrap';

import './index.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { CategoryCard } from '../../modules/cs/main/components/category-card';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';

const AshIndexPage: React.FC = () => {
  return (
    <DashboardLayout className={'home-page ash '} game="ash">
      <div className="dashboard-hero">
        <h1>Prydwen.gg - Ash Echoes wiki and tier list</h1>
        <h2>
          Prydwen.gg is a wiki for Ash Echoes Global - a gacha game published by{' '}
          <OutboundLink href="https://ae.neocraftstudio.com/en" target="_blank">
            Neocraft
          </OutboundLink>
          . Check our guides, tier lists and reviews for characters available in
          the game.
        </h2>
        <p>For the official Ash Echoes Global website go here:</p>
        <div className="centered-links">
          <div>
            <OutboundLink
              href="https://ae.neocraftstudio.com/en"
              target="_blank"
            >
              <StaticImage
                src="../../images/ash/neocraft_logo.webp"
                alt="A-Level"
                className="partner-logo"
              />
            </OutboundLink>
          </div>
          <div>
            <OutboundLink
              href="https://ae.neocraftstudio.com/download"
              target="_blank"
            >
              <Button variant="white">
                <FontAwesomeIcon icon={faDownload} width="18" /> Download Ash
                Echoes
              </Button>
            </OutboundLink>
          </div>
        </div>
        <p className="with-margin-top">
          <strong>The links above don't work for you?</strong> If you can't
          access the links posted above, you can directly download Ash Echoes
          for your mobile phone{' '}
          <a
            href="https://accounts.neocraftstudio.com/download?op_id=2117&game_id=199"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{' '}
          (via APK) and the official PC client from{' '}
          <a
            href="https://static.neocraftstudio.com/static/download/AE/seed_setup_us_pro_1.5.7.exe"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <SectionHeader title="Important Content" />
      <div className="promoted-content">
        <div className="banner-promo lel">
          <h4>Should you pull Leilani?</h4>
          <Link to="/ash-echoes/guides/should-you-pull-leilani">
            <Button variant="white">Read more</Button>
          </Link>
        </div>
      </div>
      <SectionHeader title="Guides" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Introduction to the game"
          link="/ash-echoes/guides/introduction-to-the-game"
          image={
            <StaticImage
              src="../../images/ash/categories/category_intro.webp"
              alt="Introduction to the game"
            />
          }
        />
        <CategoryCard
          title="Beginner Guide"
          link="/ash-echoes/guides/beginner-guide"
          image={
            <StaticImage
              src="../../images/ash/categories/category_beginner.webp"
              alt="Beginner Guide"
            />
          }
        />
        <CategoryCard
          title="Reroll Guide"
          link="/ash-echoes/guides/reroll"
          image={
            <StaticImage
              src="../../images/ash/categories/category_reroll.webp"
              alt="Reroll Guide"
            />
          }
        />
        <CategoryCard
          title="Elements & Reactions"
          link="/ash-echoes/guides/elements-and-reactions"
          image={
            <StaticImage
              src="../../images/ash/categories/category_elements.webp"
              alt="Elements & Reactions"
            />
          }
        />
        <CategoryCard
          title="Team Building"
          link="/ash-echoes/guides/team-building"
          image={
            <StaticImage
              src="../../images/ash/categories/category_teams.webp"
              alt="Team Building"
            />
          }
        />
        <CategoryCard
          title="Characters"
          link="/ash-echoes/characters"
          image={
            <StaticImage
              src="../../images/ash/categories/category_characters.webp"
              alt="Characters"
            />
          }
        />
        <CategoryCard
          title="Tier List"
          link="/ash-echoes/tier-list"
          image={
            <StaticImage
              src="../../images/ash/categories/category_tier.webp"
              alt="Tier List"
            />
          }
        />
        <CategoryCard
          title="Tea Time Drinks"
          link="/ash-echoes/tea-time"
          image={
            <StaticImage
              src="../../images/ash/categories/category_tea.webp"
              alt="Tea Time Drinks"
            />
          }
        />
        <CategoryCard
          title="Inheritance Guide"
          link="/ash-echoes/guides/inheritance-guide"
          image={
            <StaticImage
              src="../../images/ash/categories/category_inherit.webp"
              alt="Inheritance Guide"
            />
          }
          isNew
        />
        <CategoryCard
          title="Game Modes"
          link="/ash-echoes/guides/game-modes"
          image={
            <StaticImage
              src="../../images/ash/categories/category_modes.webp"
              alt="Game Modes"
            />
          }
        />
        <CategoryCard
          title="Echoing Nexus Overview"
          link="/ash-echoes/guides/echoing-nexus"
          image={
            <StaticImage
              src="../../images/ash/categories/category_nexus.webp"
              alt="Echoing Nexus Overview"
            />
          }
        />
        <CategoryCard
          title="Memory Traces"
          link="/ash-echoes/memory-traces"
          image={
            <StaticImage
              src="../../images/ash/categories/category_traces.webp"
              alt="Memory Traces"
            />
          }
        />
      </Row>
      <SectionHeader title="Community" />
      <CommunitySection />
    </DashboardLayout>
  );
};

export default AshIndexPage;

export const Head: React.FC = () => (
  <Seo
    title="Ash Echoes Wiki & Database | Prydwen Institute"
    description="Prydwen.gg is a wiki for Ash Echoes. Check our guides, tier lists and reviews for characters available in the game."
    game="ash"
  />
);
